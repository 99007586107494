import React from 'react';
import { Redirect } from 'react-router-dom';
import { FuseLoadable } from '@fuse';

export const WorkOrdersAppConfig = {
    settings: {
        layout: {}
    },
    routes: [
        {
            path: '/apps/workorders/order/:orderId',
            component: FuseLoadable({
                loader: () => import('./order/Order')
            })
        },
        {
            path: '/apps/workorders/orders',
            component: FuseLoadable({
                loader: () => import('./orders/WorkOrders')
            })
        },
        

        // this is the default in case the route is wrong
        {
            path: '/apps/workorders',
            component: () => <Redirect to="/apps/workorders/orders" />
        }
    ]
};
