import history from 'history.js';
import { setDefaultSettings } from 'store/actions/fuse';
import { FuseDefaultSettings } from '@fuse';
import _ from '@lodash';
import store from 'store';
import * as Actions from 'store/actions';
import firebase from 'firebase/app';
import firebaseService from 'firebaseService';
import auth0Service from 'auth0Service';
import jwtService from 'jwtService';
import axios from 'axios/index';
import { showMessage } from 'store/actions/fuse';

export const SET_USER_DATA = '[USER] SET DATA';
export const REMOVE_USER_DATA = '[USER] REMOVE DATA';
export const USER_LOGGED_OUT = '[USER] LOGGED OUT';

/**
 * Set user data from Auth0 token data
 */
export function setUserDataAuth0(tokenData, pathnameValue) {

    console.log('setUserDataAuth0 tokenData', JSON.stringify(tokenData));

    // if the user has an account link to it  already skip the back end
    if (tokenData.user_metadata != null && tokenData.user_metadata.account != null) {
        console.log('user already linked to an account =>' + tokenData.user_metadata.account);

        return setUserDataAuth0ForAuthorizedUsers(tokenData, false, pathnameValue)
    }

    // new user, unlinked account
    const request = axios.post('http://localhost:7071/api/LoadAccountInfo', {
        tokenData
    });

    // 
    return (dispatch) =>
        request.then((response) => {

            console.log('verified account =>' + JSON.stringify(response.data.data.verified));

            if (response.data.data.verified) {
                // sucess message
                dispatch(showMessage(
                    {
                        message: 'Account verified',//text or html
                        autoHideDuration: 6000,//ms
                        anchorOrigin: {
                            vertical: 'top',//top bottom
                            horizontal: 'center'//left center right
                        },
                        variant: 'success'//success error info warning null
                    }
                ));

                // save account and link it to account
                // console.log('verified account =>' + JSON.stringify(response.data.data));
                return dispatch(setUserDataAuth0ForAuthorizedUsers(response.data.data, true, pathnameValue))
            }
            else {

                dispatch(showMessage(
                    {
                        message: 'Account not verified, please contact your admin ',//text or html
                        autoHideDuration: 600000,//ms
                        anchorOrigin: {
                            vertical: 'top',//top bottom
                            horizontal: 'center'//left center right
                        },
                        variant: 'error'//success error info warning null
                    }
                ));

               

                auth0Service.logout();

                 // send it back to login
                 const pathname = '/login';
                 history.push({
                     pathname
                 });

                 return null;
                //return setUserData(tokenData);
            }
        });
}

export function setUserDataAuth0Register(tokenData, pathnameValue, values) {

    console.log('setUserDataAuth0Register tokenData', JSON.stringify(tokenData));

    //in case the user already is a member => send a message and allow access
    if (tokenData.user_metadata != null && tokenData.user_metadata.account != null) {
        console.log('setUserDataAuth0Register user already linked to an account =>' + tokenData.user_metadata.account);

        return setUserDataAuth0ForAuthorizedUsers(tokenData, false, pathnameValue)
    }

    // new user, unlinked account
    const request = axios.post('http://localhost:7071/api/CreateAccount', {
        tokenData, values
    });

    // 
    return (dispatch) =>
        request.then((response) => {

            //console.log('setUserDataAuth0Register verified account =>' + JSON.stringify(response.data.data.verified));

            if (response.data.data.verified) {
                // sucess message
                dispatch(showMessage(
                    {
                        message: 'Account verified',//text or html
                        autoHideDuration: 6000,//ms
                        anchorOrigin: {
                            vertical: 'top',//top bottom
                            horizontal: 'center'//left center right
                        },
                        variant: 'success'//success error info warning null
                    }
                ));

                // save account and link it to account
                console.log('setUserDataAuth0Register verified account =>' + JSON.stringify(response.data.data));
                return dispatch(setUserDataAuth0ForAuthorizedUsers(response.data.data, true, pathnameValue))
            }
            // else {

            //     dispatch(showMessage(
            //         {
            //             message: 'Account not verified, please contact your admin ',//text or html
            //             autoHideDuration: 600000,//ms
            //             anchorOrigin: {
            //                 vertical: 'top',//top bottom
            //                 horizontal: 'center'//left center right
            //             },
            //             variant: 'error'//success error info warning null
            //         }
            //     ));

            //     // send it back to login
            //     const pathname = '/login';
            //     history.push({
            //         pathname
            //     });

            //     auth0Service.logout();

            //     return setUserData(tokenData);
            // }
        });
}

export function setUserDataAuth0ForAuthorizedUsers(tokenData, isUpdate = false, pathnameValue) {

    //console.log('setUserDataAuth0ForAuthorizedUsers tokenData', JSON.stringify(tokenData));

    const user = {
        role: 'admin',// (tokenData.user_metadata && tokenData.user_metadata.role) ? tokenData.user_metadata.role : '',
        from: 'auth0',
        data: {
            displayName: tokenData.username,
            photoURL: tokenData.picture,
            email: tokenData.email,
            name: tokenData.name,
            settings: (tokenData.user_metadata && tokenData.user_metadata.settings) ? tokenData.user_metadata.settings : {},
            shortcuts: (tokenData.user_metadata && tokenData.user_metadata.shortcuts) ? tokenData.user_metadata.shortcuts : [],
            account: (tokenData.user_metadata && tokenData.user_metadata.account) ? tokenData.user_metadata.account : '',
            accountName: (tokenData.user_metadata && tokenData.user_metadata.accountName) ? tokenData.user_metadata.accountName : '',
            role: (tokenData.user_metadata && tokenData.user_metadata.role) ? tokenData.user_metadata.role : '',

        }
    };

    //console.log('setUserDataAuth0ForAuthorizedUsers user', JSON.stringify(user));

    if (isUpdate) updateAccountUserData(user);

   // this is the most important on login or registration
    const pathname = pathnameValue && pathnameValue != '/login' && pathnameValue != '/' ? pathnameValue : '/apps/profile';
    history.push({
        pathname
    });

    return setUserData(user);
}

/**
 * Set user data from Firebase data
 */
export function setUserDataFirebase(user, authUser) {
    if (user && user.data &&
        user.data.settings &&
        user.data.settings.theme &&
        user.data.settings.layout &&
        user.data.settings.layout.style) {
        // Set user data but do not update
        return setUserData(user);
    }
    else {
        // Create missing user settings
        return createUserSettingsFirebase(authUser);
    }
}

/**
 * Create User Settings with Firebase data
 */
export function createUserSettingsFirebase(authUser) {
    return (dispatch, getState) => {
        const guestUser = getState().auth.user;
        const fuseDefaultSettings = getState().fuse.settings.defaults;
        const currentUser = firebase.auth().currentUser;

        /**
         * Merge with current Settings
         */
        const user = _.merge({}, guestUser,
            {
                uid: authUser.uid,
                from: 'firebase',
                role: "admin",
                data: {
                    displayName: authUser.displayName,
                    email: authUser.email,
                    settings: { ...fuseDefaultSettings }
                }
            }
        );
        currentUser.updateProfile(user.data);

        updateUserData(user);
        return dispatch(setUserData(user));
    }
}

/**
 * Set User Data
 */
export function setUserData(user) {
    //console.log('Register setUserData', JSON.stringify(user))

    return (dispatch) => {

        /*
        Set User Settings
         */
        dispatch(setDefaultSettings(user.data.settings));

        /*
        Set User Data
         */
        dispatch({
            type: SET_USER_DATA,
            payload: user
        })
    }
}

/**
 * Update User Settings
 */
export function updateUserSettings(settings) {
    return (dispatch, getState) => {
        const oldUser = getState().auth.user;
        const user = _.merge({}, oldUser, { data: { settings } });

        updateUserData(user);

        return dispatch(setUserData(user));
    }
}

/**
 * Update User Shortcuts
 */
export function updateUserShortcuts(shortcuts) {
    return (dispatch, getState) => {
        const user = getState().auth.user;
        const newUser = {
            ...user,
            data: {
                ...user.data,
                shortcuts
            }
        };

        updateUserData(newUser);

        return dispatch(setUserData(newUser));
    }
}

/**
 * Remove User Data
 */
export function removeUserData() {
    return {
        type: REMOVE_USER_DATA
    }
}

/**
 * Logout
 */
export function logoutUser() {

    return (dispatch, getState) => {

        const user = getState().auth.user;

        //console.log('logoutUser', user);

        if (user.role === 'guest') {
            return null;
        }

        history.push({
            pathname: '/login'
        });

        switch (user.from) {
            case 'firebase':
                {
                    firebaseService.signOut();
                    break;
                }
            case 'auth0':
                {
                    auth0Service.logout();
                    break;
                }
            default:
                {
                    jwtService.logout();
                }
        }

        dispatch(setDefaultSettings(FuseDefaultSettings));

        dispatch({
            type: USER_LOGGED_OUT
        })
    }
}

/**
 * Update User Data
 */
function updateUserData(user) {
    if (user.role === 'guest') {
        return;
    }

    switch (user.from) {
        case 'firebase':
            {
                firebaseService.updateUserData(user)
                    .then(() => {
                        store.dispatch(Actions.showMessage({ message: "User data saved to firebase" }));
                    })
                    .catch(error => {
                        store.dispatch(Actions.showMessage({ message: error.message }));
                    });
                break;
            }
        case 'auth0':
            {
                auth0Service.updateUserData({
                    settings: user.data.settings,
                    shortcuts: user.data.shortcuts
                })
                    .then(() => {
                        store.dispatch(Actions.showMessage({ message: "User data saved to auth0" }));
                    })
                    .catch(error => {
                        store.dispatch(Actions.showMessage({ message: error.message }));
                    });
                break;
            }
        default:
            {
                jwtService.updateUserData(user)
                    .then(() => {
                        store.dispatch(Actions.showMessage({ message: "User data saved with api" }));
                    })
                    .catch(error => {
                        store.dispatch(Actions.showMessage({ message: error.message }));
                    });
                break;
            }
    }
}

function updateAccountUserData(user) {
    if (user.role === 'guest') {
        return;
    }

    switch (user.from) {
        case 'firebase':
            {
                firebaseService.updateUserData(user)
                    .then(() => {
                        store.dispatch(Actions.showMessage({ message: "User data saved to firebase" }));
                    })
                    .catch(error => {
                        store.dispatch(Actions.showMessage({ message: error.message }));
                    });
                break;
            }
        case 'auth0':
            {
                auth0Service.updateUserData({
                    // more about the account on load
                    account: user.data.account,
                    accountName: user.data.accountName,
                    role: user.data.role
                })
                    .then(() => {
                        store.dispatch(Actions.showMessage({ message: "User account data saved to auth0" }));
                    })
                    .catch(error => {
                        store.dispatch(Actions.showMessage({ message: error.message }));
                    });
                break;
            }
        default:
            {
                jwtService.updateUserData(user)
                    .then(() => {
                        store.dispatch(Actions.showMessage({ message: "User data saved with api" }));
                    })
                    .catch(error => {
                        store.dispatch(Actions.showMessage({ message: error.message }));
                    });
                break;
            }
    }
}
