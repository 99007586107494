import React from 'react';
import {Redirect} from 'react-router-dom';
import {FuseLoadable} from '@fuse';

export const WorkOrderAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [        
        {
            path     : '/apps/workorder/:orderId',
            component: FuseLoadable({
                loader: () => import('./WorkOrderApp')
            })
        },
        {
            path     : '/apps/workorder',
            component: () => <Redirect to="/apps/workorder/workorder/products"/>
        }
    ]
};
