import React from 'react';
import {Redirect} from 'react-router-dom';
import {FuseLoadable} from '@fuse';
import {authRoles} from 'auth';

export const ProfileAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    //auth    : authRoles.onlyGuest,//['guest']
    routes  : [
        {
            path     : '/apps/profile',
            component: FuseLoadable({
                loader: () => import('./ProfileApp')
            })
        },
        // {
        //     path     : '/apps/profile/:profileId?',
        //     component: FuseLoadable({
        //         loader: () => import('./ProfileApp')
        //     })
        // },
        {
            path     : '/apps/profile',
            component: () => <Redirect to="/apps/profile"/>
        }
    ]
};


