import {authRoles} from 'auth';
//import Register from './Register';
import {FuseLoadable} from '@fuse';


export const RegisterConfig = {
    settings: {
        layout: {
            config: {
                navbar : {
                    display: false
                },
                toolbar: {
                    display: false
                },
                footer : {
                    display: false
                },
                leftSidePanel : {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    auth    : authRoles.onlyGuest,
    routes  : [
        {
            path     : '/register',
            //component: Register
            component: FuseLoadable({
                loader: () => import('./Register')
            })
        }
    ]
};