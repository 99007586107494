import React from 'react';
import {Redirect} from 'react-router-dom';
import {FuseLoadable} from '@fuse';

export const InventoryAppConfig = {
    settings: {
        layout: {}
    },
    routes  : [
        {
            path     : '/apps/inventory/products/:productId/:productHandle?',
            component: FuseLoadable({
                loader: () => import('./product/Product')
            })
        },
        {
            path     : '/apps/inventory/products',
            component: FuseLoadable({
                loader: () => import('./products/Products')
            })
        },
        

        // this is the default in case the route is wrong
        {
            path     : '/apps/inventory',
            component: () => <Redirect to="/apps/inventory/products"/>
        }
    ]
};
