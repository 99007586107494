import React from 'react';
import { Redirect } from 'react-router-dom';
import { FuseLoadable } from '@fuse';

export const OrdersAppConfig = {
    settings: {
        layout: {}
    },
    routes: [
        {
            path: '/apps/orders/order/:orderId',
            component: FuseLoadable({
                loader: () => import('./order/Order')
            })
        },
        {
            path: '/apps/orders/purchaseorders',
            component: FuseLoadable({
                loader: () => import('./purchaseorders/PurchaseOrders')
            })
        },

        // this is the default in case the route is wrong
        {
            path: '/apps/orders',
            component: () => <Redirect to="/apps/purchaseorders/PurchaseOrders" />
        }
    ]
};
