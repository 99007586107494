import {AnalyticsDashboardAppConfig} from './dashboards/analytics/AnalyticsDashboardAppConfig';
import {MailAppConfig} from './mail/MailAppConfig';
import {TodoAppConfig} from './todo/TodoAppConfig';
import {FileManagerAppConfig} from './file-manager/FileManagerAppConfig';
import {CalendarAppConfig} from './calendar/CalendarAppConfig';
import {ChatAppConfig} from "./chat/ChatAppConfig";
import {InventoryAppConfig} from './inventory/InventoryAppConfig';
import {ScrumboardAppConfig} from './scrumboard/ScrumboardAppConfig';
import {AcademyAppConfig} from './academy/AcademyAppConfig';
import {ExampleAppConfig} from  './example/ExampleAppConfig';
import {ProfileAppConfig} from  './profile/ProfileAppConfig';
import {OrdersAppConfig} from './orders/OrdersAppConfig';

import {WorkOrderAppConfig} from './workorder/WorkOrderAppConfig';
import {WorkOrdersAppConfig} from './workorders/WorkOrdersAppConfig';


export const appsConfigs = [
    AnalyticsDashboardAppConfig,
    CalendarAppConfig,
    ProfileAppConfig,
    InventoryAppConfig,
    OrdersAppConfig,
    MailAppConfig,
    TodoAppConfig,
    FileManagerAppConfig,
    ChatAppConfig,
    ScrumboardAppConfig,
    AcademyAppConfig,
    ExampleAppConfig,

    WorkOrderAppConfig,
    WorkOrdersAppConfig,


];
