//import React from 'react';
//import {Redirect} from 'react-router-dom';
import {FuseLoadable} from '@fuse';

export const ExampleAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/apps/example',
            component: FuseLoadable({
                loader: () => import('./ExampleApp')
            })
       }
       //,
        // {
        //     path     : '/apps/profile',
        //     component: () => <Redirect to="/apps/profile"/>
        // }
    ]
};

